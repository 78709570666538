.addicon {
    display: flex;
    /* margin-left: 5%; */
    margin-bottom: 5px;
    cursor: pointer;
    margin-right: 60%;
    padding-left: 5%;
    /* padding-top: 5px; */
    padding-bottom: 5px;
    border-radius: 10px;
    color: #85929d;
}

.addicon:hover {
    background-color: #eef3fb;
}

.inboxdata {
    display: flex;
    /* margin-left: 4%; */
    margin-bottom: 3%;
    cursor: pointer;
    padding: 9px;
    padding-left: 5%;
    margin-right: 2%;
    border-radius: 10px;
}

.inboxdata:hover {
    background-color: #eef3fb;
}