.new-call-widget {
  background: #fff;
  padding: 0 0 0 0;
  color: #fff;
  border-radius: 15px 15px 0 0 !important;
  position: fixed !important;
  bottom: 0px;
  right: 20px;
  max-width: 380px;
  min-width: 300px;
  border: 1px solid #0045BA;
  z-index: 1060 !important;
  box-sizing: border-box;

}

.connected-call .new-call-widget .MuiAppBar-colorPrimary {
  background: #01da8a !important;

}

.connected-call .new-call-widget {
  border: 1px solid #01da8a !important;
}

.cloud-phone-appbar-btn {
  background: #fff !important;
  border-radius: 10px !important;
  min-width: 34px !important;
  margin-left: 5px !important;
}

.cloud-phone-appbar-btn img,
.cloud-phone-appbar-btn svg {
  width: 16px !important;
  height: 16px !important;
}

.new-call-widget.expand {
  min-width: 768px;
  min-height: 472px;
  max-width: 100%;
}

.number-search-input {
  border-radius: 30px;
  background: rgba(196, 196, 196, .2);
  height: 50px;
  /* padding: 5px 20px !important; */
}

.number-search-input .MuiInputBase-root {
  border: 0 solid #fff !important;
}

.number-search-input .MuiInputBase-root::before {
  border: 0 solid #fff !important;
}

.new-call-widget .dial-pad.call-type.number-listing {
  height: 395px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.new-call-widget img{
  border-radius: 3px;
}

.country-list {
  background-color: #fff;
  border-radius: 7px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  box-shadow: 1px 2px 18px rgb(0 0 0 / 25%);
  list-style: none;
  margin: 0px 0 10px -1px;
  max-height: 220px;
  outline: none;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  width: 100%;
  max-width: 100%;
  z-index: 1;
}
.country-list input{
  width: calc(100% - 20px);
  border-radius: 3px;
}
.country-list img{
  border-radius: 3px;
  margin-left: 10px;
}
.country-list button{
  padding-top: 0;
}

.new-call-widget .dial-pad.call-type.number-listing .MuiPaper-rounded {
  padding: 20px 0px;
}

.new-call-widget .dial-pad.call-type.number-listing .MuiFormControl-root {
  display: flex;
}

.radio-number-group {
  height: 305px;
  padding-bottom: 50px;
  overflow-y: auto;
}

/* .radio-number-group li:hover .MuiButtonBase-root, .radio-number-group li .MuiButtonBase-root{
  padding: 4px 48px 4px 16px !important;
} */
.callfrom-btn {
  text-transform: capitalize !important;
  border: 1px solid #0045BA !important;
  font-size: 11px !important;
  width: 80px !important;
  border-radius: 20px !important;
}

.new-call-widget.fixed {
  transform: translate(0px, 0px) !important;
  max-height: 675px;
  height: auto !important;
}

.new-call-widget.fixed .call-status {
  display: block;
  margin-bottom: 10px;
}

.new-call-widget.fixed .call-status .connected-call {
  padding: 5px 10px;
  background: #01da8a;
  border-radius: 5px 5px 0 0;
  display: block;
  animation: connected 2s infinite;
  text-align: center;
}


.disconnected-call {
  padding: 5px 10px;
  background: #ec3608;
  border-radius: 5px 5px 0 0;
  display: block;
  animation: connected 2s infinite;
  text-align: center;
}

.number-list .phone-flag-only {
  border-bottom: 1px solid #eee;

}

.number-list .react-tel-input .form-control {
  border: 0 solid #fff;
  background: rgba(0, 0, 0, 0) !important;
  padding: 5px 4px 5px 58px !important;
}

.number-list .react-tel-input:hover .form-control {
  background: rgba(0, 0, 0, 0) !important;
  border: 0 solid #fff;
}

.number-list .react-tel-input .special-label {
  display: none;
}

@keyframes connected {
  0% {
    background-color: #01da8a;
  }

  50% {
    background-color: #30fdb2
  }

  100% {
    background-color: #01da8a;
  }
}

.appbar-select{
  margin-right: 5px !important;
}
.appbar-select .MuiSelect-select{
  padding: 2px 30px 2px 10px;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
  
}
.appbar-button{
  height: 28px;
  color: #fff !important;
  font-weight: 400 !important;
  padding-top: 5px;
}

.new-call-widget.fixed .connected-expanded-call {
  display: none;
}

.new-call-widget.expand .connected-expanded-call {
  display: block;
}

.new-call-widget.expand .connected-fixed-call {
  display: none;
}

.new-call-widget.fixed .connected-fixed-call {
  display: block;
}

.new-call-widget.expand .call-status {
  display: none;
}

.new-call-widget.expand .call-progress {
  max-height: 400px;

}

.new-call-widget.fixed .call-progress {
  height: 380px;
  overflow-y: auto;
}

.new-call-widget.fixed .dialing-call-window {
  height: 435px;
}

.new-call-widget.expand .dialing-call-window {
  height: 370px;
}

.new-call-widget .MuiAppBar-colorPrimary {
  background: #0045BA;
}

.new-call-widget .MuiAppBar-colorPrimary .MuiToolbar-regular {
  min-height: 10px;
  padding: 5px 10px;
  border-radius: 3px 3px 0 0 !important;
}

.call-widjet-bottom-appbar {
  border-radius: 30px 30px 0 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff !important;
  z-index: 1030;
}

.call-widjet-bottom-appbar .MuiToolbar-regular {
  justify-content: space-around;
}

.call-widjet-action-appbar {
  box-shadow: none !important;
  margin: 10px 0 10px 0;
}

.call-widjet-action-appbar .MuiToolbar-regular {
  justify-content: space-around;
}

.call-widjet-number-appbar {
  box-shadow: none !important;
  display: "flex";
  flex-direction: column;

}

.dial-phone-number .react-tel-input .special-label {
  display: none;
}

.dial-dropdown {
  width: 70px !important;
  font-size: 11px !important;
  min-width: 70px !important;
  max-width: 70px !important;
  border: 1px solid #0045BA;
  background: #fff;
  border-radius: 20px;
  padding: 5px 5px;
}

.dial-phone-number .react-tel-input .form-control,
.dial-phone-number .react-tel-input .form-control:focus {
  border: 0 solid #fff !important;
  outline: none !important;
}

.call-widjet-number-appbar .MuiToolbar-regular {
  justify-content: space-around;
  min-height: 50px;
}

.new-call-widget .dial-pad {
  padding: 10px 0 0px 0 !important;
  /* z-index: 1025; */
  width: 100%;
  background: #fff;
  border: 1px solid #0045BA;
  border-radius: 15px 15px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.new-call-widget .dial-pad .MuiPaper-rounded {
  padding: 0px 0 70px 0;
}

.new-call-widget .dial-pad.call-progress {
  border-radius: 0 !important;
  padding: 0px 0 0px 0 !important;
}

.new-call-widget .dial-pad.call-progress .MuiPaper-rounded {
  padding: 20px 10px 20px 10px;
}

.new-call-widget .dial-pad.call-type {
  z-index: 1026;
  height: 390px;
  border-radius: 0;
  padding: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.new-call-widget .dial-pad.call-type .MuiPaper-rounded {
  padding: 0px 0 50px 0;
}

.new-call-widget .react-tel-input .form-control {
  width: 100% !important;
}

.new-call-widget .page-header {
  box-shadow: none;
  border-bottom: 1px solid #eee;
  background: rgb(241, 244, 246);
}

.new-call-widget .page-header .MuiToolbar-regular {
  min-height: 10px;
}

.new-call-widget .page-header .MuiToolbar-regular .MuiInput-underline::before {
  border-bottom: 0;
}

.alphabet {
  height: 16px;
  font-size: 10px;
}

.no-alphabet {
  height: 8px;
  font-size: 10px;
}

.dial-btn {
  font-size: 21px !important;
  color: #0045BA !important;
  font-weight: 700;
  line-height: 16px !important;
}

.conversation-list {
  height: 450px;
  overflow-y: auto;
  display: block;
  padding-bottom: 50px;
}

.settigns-list {
  height: 436px;
  overflow-y: auto;
  display: block;
  padding: 20px 20px 50px 20px;
}

.new-call-widget.expand .conversation-list,
.new-call-widget.expand .settigns-list {
  height: 380px !important;
  padding: 20px 20px 20px 20px;
}

.settigns-list .MuiFormLabel-root {
  background: #fff;
}

.new-call-widget .dial-pad .MuiAccordion-root {
  padding: 0px 0 0px 0 !important;
}

.new-call-widget .dial-pad .MuiIconButton-root {
  padding: 0;
}

.new-call-widget .dial-pad .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.new-call-widget .dial-pad .MuiAccordionSummary-root.Mui-expanded {
  min-height: 10px;
}

.new-call-widget .dial-pad .MuiTableCell-root {
  padding: 5px 0;
}

.new-call-widget .dial-pad .MuiAccordionDetails-root {
  padding: 8px 16px 0px;
}

.dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root {
  border: 0;
  border-radius: 0ch;
  width: 100%;
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #0045BA;
  background: #eee !important;
  color: #aaa !important;
}

.dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root.active-radio-call {
  background: #fff !important;
  color: #000 !important;
}

.dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root:last-child {
  border-bottom: 0px solid #fff;
}

.dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root h5 {
  text-align: left;
  font-weight: 700;
  font-size: 18px;
}

.dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root span {
  text-align: left;
  font-weight: 400;
  font-size: 11px !important;
  display: block;
}

.dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root li {
  padding: 0;
  font-size: 11px !important;
  align-items: flex-start;
}

.dial-pad.call-type .custom-radio-calltype .MuiListItemIcon-root {
  margin-top: 6px;
}

.dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root li svg {
  width: 18px !important;
  height: 18px !important;
  color: #0045BA;
  fill: #0045BA;
}

.internet-call-img {
  width: 90px;
}

.radio-active-img {
  position: absolute;
  width: 200px;
  right: 0;
}

.connected-flag {
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  display: block;
  margin-bottom: 20px !important;
}

.connected-name {
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  display: block;
}

.connected-number {
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  display: block;
}

.connected-timer {
  font-weight: 700;
  color: #fff;
  font-size: 36px;
  display: block;
}

.history-head {
  font-weight: 700 !important;
  font-size: 18px !important;
  display: block;
}

.icon-text-btn {
  color: #fff !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  font-weight: 300 !important;
}

.icon-text-btn svg {
  color: #fff !important;
  font-size: 18px !important;
  width: 24px !important;
  height: 24px !important;
}

.icon-contained-btn {
  background: #fff !important;
  color: #0045BA !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
}

.icon-contained-btn svg {
  color: #0045BA !important;
  width: 24px !important;
  height: 24px !important;
}

.new-call-widget.fixed .connected-call-window {
  height: 435px;
  border-radius: 5 !important;
  text-align: center;
  padding: 0 !important;
  border-top-width: 0px;
}

.fixed-connect-call {
  background: #0045BA;
  display: block;
  height: 250px;
  padding: 10px !important;
  width: 100%;
}

.new-call-widget.expand .connected-call-window {
  height: 440px;
  overflow-y: auto;
}

.call-phone-flag-only .special-label {
  display: none !important;
}

.call-phone-flag-only .flag .arrow {
  display: none !important;
}

.call-phone-flag-only .react-tel-input .form-control {
  width: auto;
  padding: 3px 0px 3px 25px;
  border: none;
  background: none;
  font-size: 12px;
}

.call-phone-flag-only:hover .react-tel-input .form-control {
  border: none;
}

.call-phone-flag-only .react-tel-input .selected-flag {
  padding-left: 0;
}

.call-phone-flag-only .react-tel-input .flag {
  width: 20px;
  height: 18px;
}

.recording {
  color: #fff;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  font-size: 12px;
  top: 5px;
  left: 5px;
}

.recording svg {
  color: #f50057;
  fill: #f50057;
}

.tags-list {
  width: 100%;
  max-height: 200px;
  overflow: auto;
}



.history-sec,
.notes-sec {
  border: 1px solid #0045BA;
  border-radius: 15px 15px 0 0 !important;
}

.incoming,
.outgoing {
  background: #01da8a !important;
}

.missed,
.failed {
  background: #ec3608 !important;
}

.interested {
  fill: #01da8a;
  color: #01da8a;
}

.notinterested {
  fill: #ec3608;
  color: #ec3608;
}

.busy {
  fill: #FF5C00;
  color: #FF5C00;
}

.wrongnumber {
  fill: #5E6AFF;
  color: #5E6AFF;
}

.MuiGrid-item .MuiAvatar-root {
  width: 30px;
  height: 30px;
}

/********************call animation******************/


* {
  box-sizing: border-box;
}

.dialing-icon-cover {
  width: 90px;
  margin-left: 12px;
}

.dialing-icon-animate {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
}

.phone {
  z-index: 2;
  background-color: #f50057;
  animation: phone 1s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite alternate-reverse;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 0.5px white solid;
}

.material-icons {
  color: white;

}

.animate-call {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  animation: scaling 2s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite;
  line-height: 50px;
}

@keyframes scaling {
  0% {
    transform: scale(1);
    background-color: #f50057;
  }

  50% {
    transform: scale(1.5);
    background-color: rgba(245, 0, 182, 0);
  }

  100% {
    transform: scale(1);
    background-color: #f50057;
  }
}

@keyframes phone {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}





/**dialing*********/
.phone-dialing {
  z-index: 2;
  background-color: #0045BA;
  animation: phonedialing 1s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite alternate-reverse;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 0.5px white solid;
}

.animate-call-dialing {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #0d65fd;
  position: absolute;
  animation: scalingdialing 2s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite;
  line-height: 50px;
}

@keyframes scalingdialing {
  0% {
    transform: scale(1);
    background-color: #0045BA;
  }

  50% {
    transform: scale(1.5);
    background-color: rgba(245, 0, 182, 0);
  }

  100% {
    transform: scale(1);
    background-color: #0045BA;
  }
}

@keyframes phonedialing {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}



/**connected*********/
.phone-connected {
  z-index: 2;
  background-color: #01da8a;
  animation: phoneconneted 1s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite alternate-reverse;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 0.5px white solid;
}

.animate-call-connected {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #0ada8ae7;
  position: absolute;
  animation: scalingconneted 2s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite;
  line-height: 50px;
}

@keyframes scalingconneted {
  0% {
    transform: scale(1);
    background-color: #01da8a;
  }

  50% {
    transform: scale(1.5);
    background-color: rgba(245, 0, 182, 0);
  }

  100% {
    transform: scale(1);
    background-color: #01da8a;
  }
}

@keyframes phoneconneted {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.circle1 {
  animation-delay: 0s;
}

.circle2 {
  animation-delay: 1s;
}


/**ringing*********/
.phone-ringing {
  z-index: 2;
  background-color: #f77347;
  animation: phoneringing 1s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite alternate-reverse;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 0.5px #fff solid;
}

.animate-call-ringing {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  animation: scalingringing 2s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite;
  line-height: 50px;
}

@keyframes scalingringing {
  0% {
    transform: scale(1);
    background-color: #f77347;
  }

  50% {
    transform: scale(1.5);
    background-color: rgba(245, 0, 182, 0);
  }

  100% {
    transform: scale(1);
    background-color: #f77347;
  }
}

@keyframes phoneringing {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.circle1 {
  animation-delay: 0s;
}

.circle2 {
  animation-delay: 1s;
}

/********************call animation******************/


.new-call-widget .MuiInputLabel-formControl {
  font-size: 12px !important;

}

.new-call-widget .MuiInputLabel-formControl.Mui-focused {
  font-size: 12px !important;
}

.from-select .MuiSelect-select {
  white-space: nowrap;
  overflow: hidden;
  /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
  width: 100%;
  direction: rtl;
  text-align: left;
}

.MuiInputLabel-root {
  /* background: #fff; */
}

.new-call-widget .incoming-call {
  z-index: 1101 !important;
  height: calc(100% - 40px) !important;
}

.new-call-widget .incoming-call .MuiCollapse-wrapper {
  height: 100% !important;
}

.new-call-widget .incoming-call .MuiPaper-rounded {
  padding: 60px 20px 30px 20px !important;
  height: 100% !important;
}

.incoming-call-h4 {
  font-size: 21px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
}

.incoming-call-h5 {
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 10px;
}

.incoming-call-h6 {
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 50px;
}

.accept-btn {
  border: 1px solid #01da8a !important;
  color: #01da8a !important;
  text-transform: capitalize !important;
}

.decline-btn {
  border: 1px solid #ec3608 !important;
  color: #ec3608 !important;
  text-transform: capitalize !important;
}

.accept-btn svg,
.decline-btn svg {
  width: 18px !important;
  margin-right: 10px;
}

.new-call-widget .MuiBox-root {
  padding: 0 !important;
}

.new-call-widget .MuiTab-root {
  font-size: 12px;
  text-transform: capitalize !important;
  padding: 0px 10px !important;
}

.conversation-btn-group {
  border-radius: 15px !important;
  display: flex !important;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}



.conversation-btn-group .tab-active,
.conversation-btn-group .tab-active:hover {
  background: #0045BA;
  color: #fff;
}

.conversation-btn-group .MuiButton-root {
  border-radius: 15px;
}

.conversation-btn-group .MuiButton-label {
  /* text-transform: capitalize !important; */
  font-weight: 400;
}

svg.small-icon {
  width: 16px !important;
  height: 16px !important;
}

.dial-pad-backdrop {
  z-index: 1024 !important;
  height: calc(100% - 55px);
  margin-top: 55px;
}

@media only screen and (max-width: 1300px) {

  .animate-call-ringing,
  .animate-call-connected,
  .animate-call-dialing,
  .animate-call {
    line-height: 55px;
  }
}

@media screen and (max-width: 1300px) {
  .new-call-widget.fixed .call-progress {
    height: 428px;
  }
}

@media screen and (max-width: 768px) {
  .new-call-widget.fixed.mobile-full-screen {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    right: 0 !important;
    max-width: 100%;
  }

  .new-call-widget.mobile-full-screen .dial-pad.call-type.number-listing {
    height: calc(100% - 40px) !important;
    max-height: 100% !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .new-call-widget .dial-pad.call-type,
  .call-type-paper,
  .dial-pad.call-type.number-listing .number-paper {
    height: calc(100vh - 40px) !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .dial-pad.call-type .custom-radio-calltype .MuiButtonBase-root {
    height: calc(50vh - 60px) !important;
  }

  .internet-call-img {
    width: 200px !important;
  }

  .new-call-widget.fixed .call-progress,
  .connected-call-window {
    height: calc(100vh - 40px) !important;
  }

  .cancel-call {
    margin-top: 53px;
  }

  /* .new-call-widget.fixed.mobile-full-screen .MuiCollapse-wrapper{
      height: 100vh;
    } */
  /* .new-call-widget.fixed.mobile-full-screen .MuiCollapse-wrapper .MuiPaper-root{
      height: 100vh;
    } */
}