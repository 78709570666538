.MuiDataGrid-columnHeaders{
    background-color: #F4F6F8;
}
.hovershowhide-parent .hovershow-child{
    display: none;
    transition: all 0.5s;
}
.hovershowhide-parent .hoverhide-child{
    display: block;
    transition: all 0.5s;
}

.hovershowhide-parent:hover .hovershow-child{
    display: block;
}
.hovershowhide-parent:hover .hoverhide-child{
    display: none;
}